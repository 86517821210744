import React, { useState, useEffect } from 'react'

import { motion } from 'framer-motion'
import ReactTooltip from 'react-tooltip'
import { AppWrap, MotionWrap} from '../../wrapper'
import { urlFor, client } from '../../client'

import './Skills.scss'

/*
{skills.map((skill, index) => (
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className='app__skills-item app__flex'
              key={'skill' + index}
            >
              <div className="app__flex" style={{ backgroundColor: skill.bgColor }}>
                <img src={urlFor(skill.icon)} alt={skill.name} />
              </div>
              <p className="p-text">{skill.name}</p>
            </motion.div>
          ))}
*/

const Skills = () => {
  const [experiences, setExperiences] = useState([])

  useEffect(() => {
    const query = '*[_type == "techskills"]';
    client.fetch(query)
      .then((data) => {
        setExperiences(data)
      })

  }, [])

  return (
    <>
      <h1 className="head-text"><span>Technological</span> Expertise</h1>
      <div className='app__skills-container'>
        <motion.div
          className='app__skills-exp app__skills-container'
        >
          {experiences?.map((experience, index) => (
            <motion.div className='app__skills-exp-item'
              key={'experience' + index}>
              <div className="app__skills-exp-year">
                <h4 className='bold-text'>{experience.technology}</h4>
              </div>
              <motion.div
                className='app__skills-exp-works'>
                {experience.skills_arr.map((work, index) => (
                  <div key={'experience' + index + 'work' + index}>
                    <motion.div
                      whileInView={{ opacity: [0, 1] }}
                      transition={{ duration: 0.5 }}
                      className='app__skills-exp-work app__flex'
                      data-tip={work.name}>
                      <div className="container_image">
                        <img src={urlFor(work.icon)} alt={work.name} />
                      </div>
                    </motion.div>
                    <ReactTooltip/>
                  </div>
                ))}
              </motion.div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </>
  )
}

export default AppWrap(Skills,
  'skills',
  "app__whitebg");
