import React from 'react'
import './Footer.scss'

import { useState } from 'react'
import { images } from '../../constants'

import { AppWrap, MotionWrap } from '../../wrapper'
import { client } from '../../client'

const Footer = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' })
  const [isFromSubmitted, setIsFromSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  const { name, email, message } = formData

  const handleChangeInput = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = () => {
    setLoading(true)

    const contact = {
      _type: 'contact',
      name: name,
      email: email,
      message: message
    }

    client.create(contact)
      .then(() => {
        setLoading(false)
        setIsFromSubmitted(true)
      })
  }

  return (
    <>
      <h2 className="head-text">
        Take a coffee & chat with me
      </h2>
      <div className='app__footer-cards'>
        <div className="app__footer-card">
          {/*<img src={images.email} alt='email' />*/}
          <a href="marc.combalia[at]gmail.com" className='p-text'>marc.combalia[at]gmail.com</a>
        </div>
        {/*
        <div className="app__footer-card">
          <img src={images.mobile} alt='mobile' />
          <a href="tel: +34677539555" className='p-text'>+34677539555</a>
        </div>
        */}
      </div>


      {!isFromSubmitted ?
        <div className="app__footer-form app__flex">
          <div className="app__flex">
            <input className='p-text' type="text" placeholder='Your Name' value={name} name='name' onChange={handleChangeInput} />
          </div>
          <div className="app__flex">
            <input className='p-text' type="email" placeholder='Your Email' value={email} name='email' onChange={handleChangeInput} />
          </div>
          <div>
            <textarea
              className='p-text'
              placeholder='Your Message'
              value={message}
              name="message"
              onChange={handleChangeInput}
            />
          </div>
          <button type='button' className='p-text' onClick={handleSubmit}>{loading ? 'Sending' : 'Send Message'}</button>
        </div>
        :
        <div>
          <h3 className='head-text'>Thank you for getting in touch.</h3>
        </div>
      }
    </>
  )
}

export default AppWrap(
    Footer,
  'contact', 'app__whitebg')