import React, { useState, useEffect } from 'react'
import './Projects.scss'

import { images } from '../../constants'
import { motion } from 'framer-motion'

import { urlFor, client } from '../../client'

import { AppWrap, MotionWrap } from '../../wrapper'

const Projects = () => {
  const [abouts, setAbouts] = useState([])

  useEffect(() => {
    const query = '*[_type == "projects"]';
    client.fetch(query)
      .then((data) => { setAbouts(data) })
  }, [])


  return (
    <>
      <h2 className='head-text'><span>National & International</span> Project Participation</h2>
      <div className='app__projects-container'>
        {abouts.map((about, index) => (
          <a href={about.link} key={about.title + index} target='_blank' className='app_projects-link' style={{textDecoration: 'none'}}>
            <motion.div
              whileInView={{ opacity: 1 }}
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.5, type: 'tween' }}
              className='app__projects-item'
              key={about.title + index}>
              <img src={urlFor(about.imgUrl)} alt={about.title} />
              <h2 className='bold-text'>{about.title.slice(0, 20) + '...'}</h2>
              <p className='p-text'>{about.description}</p>
              <p className='p-text'>{about.year}</p>
              <p className='p-text' >{about.funding}</p>
            </motion.div>
          </a>
        ))}
      </div>
    </>
  )
}

export default AppWrap(
  Projects,
  'projects',
  "app__whitebg");
