import React, { useState, useEffect } from 'react'
import { AiFillEye, AiFillGithub } from 'react-icons/ai'

import { motion } from 'framer-motion'
import { AppWrap, MotionWrap } from '../../wrapper'
import { urlFor, client } from '../../client'
import './Research.scss'

const Research = () => {
  const [research, setResearch] = useState([])
  const [filterResearch, setFilterResearch] = useState([])
  const [maxLen, setMaxLen] = useState(10)

  useEffect(() => {
    const query = '*[_type == "research"]';
    client.fetch(query)
      .then((data) => {
        setResearch(data)
        setFilterResearch(data)
      })
  }, [])


  const sortedResearch = research.sort((a, b) => (a.year > b.year) ? 1 : ((b.year > a.year) ? -1 : 0)).reverse();

  const [activeFilter, setActiveFilter] = useState('All')
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 })

  const showMore = () => {
    if (maxLen === 10){
      setMaxLen(research.length)
    }else{
      setMaxLen(10)
    }
}

  const handleWorkFilter = (item) => {
    setActiveFilter(item)
    setAnimateCard([{ y: 100, opacity: 0 }])

    setTimeout(() => {
      setAnimateCard([{ y: 0, opacity: 1 }])

      if (item === 'All') {
        setFilterResearch(sortedResearch)
      } else {
        setFilterResearch(sortedResearch.filter((point) => point.tags.includes(item)))
      }
    }, 500)

  }

  return (
    <>
      <h2 className='head-text'><span>Research</span> Publications</h2>
      <a href="https://scholar.google.com/citations?user=LIvAi2wAAAAJ&hl=es" target='_blank' className='g-scholar'>
        <p className='p-text'>Num Citations (03-2022): 1262</p>
        <p className='p-text'>H-Index (03-2022): 10</p>
      </a>
      <div className="app__research-filter">
        {['Journal', 'Conference', 'Patent', 'Poster', 'Thesis', 'Teaching', 'Book', 'All'].map((item, index) => (
          <div
            key={index}
            onClick={() => handleWorkFilter(item)}
            className={`app__research-filter-item app__flex p-text ${activeFilter === item ? 'item-active' : ''}`}>
            {item}
          </div>
        ))}
      </div>

      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className='app__research-portfolio'
      >
        {
          filterResearch.slice(0, maxLen).map((work, index) => (
            <div className="app__research-item app__flex" key={index}>
              <div className='app__research-content app__flex'>
                <h4 className='bold-text'>{work.title.substring(0, 25) + '...'}</h4>
                <p className='p-text app__research-content-year'>{work.year}</p>
                <div className='app__research-tag app__flex'>
                  <p className='p-text'>{work.tags[0]}</p>
                </div>
                <motion.div
                  whileHover={{ opacity: [0, 1] }}
                  transition={{ duration: 0.25, ease: 'easeInOut', staggerDirection: 0.5 }}
                  className='app__research-hover app__flex'>
                  <a href={work.link} target='_blank' rel='noreferrer'>
                    <motion.div
                      whileInView={{ scale: [0, 1] }}
                      whileHover={{ scale: [1, 0.9] }}
                      transition={{ duration: 0.25 }}
                      className='app__flex'>
                      <AiFillEye />
                    </motion.div>
                  </a>
                </motion.div>
              </div>
            </div>
          ))
        }
        <div className="app__research-item app__flex">
          <div className='app__research-content app__flex'>
            <h4 className='bold-text'>{maxLen === 10 ? 'More ...' : 'Less ...'}</h4>
            <p className='p-text app__research-content-year'></p>
            <motion.div
              whileHover={{ opacity: [0, 1] }}
              transition={{ duration: 0.25, ease: 'easeInOut', staggerDirection: 0.5 }}
              className='app__research-hover app__flex'
              onClick={showMore}
              >
            </motion.div>
          </div>
        </div>
      </motion.div>
    </>
  )
}

export default AppWrap(
  Research, 
  'research',
  "app__primarybg");