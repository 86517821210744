import React from 'react'

import {categories} from '../constants/categories'

const NavigationDots = ({ active }) => {

    return (
        <div className='app__navigation'>
            {categories.map((item, index) => {
                return (
                    <a
                        href={`#${item}`}
                        key={item + index}
                        className='app__navigation-dot'
                        style={active === item ? { backgroundColor: '#313BAC' } : {}} />
                )
            })}
        </div>
    )
}

export default NavigationDots;