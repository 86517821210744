import React from 'react';
import './VideoBackground.scss';

const VIDEO_WIDTH = 1920;
const VIDEO_HEIGHT = 1080;

const VideoBackground = () => {
  return (
    <div className="video-background">
      <iframe
        width={VIDEO_WIDTH}
        height={VIDEO_HEIGHT}
        src="https://www.youtube.com/embed/Uv_i_7jY1JA?autoplay=1&controls=0&mute=1&loop=1&modestbranding=1&showinfo=0&playlist=Uv_i_7jY1JA"
        title="YouTube video player"
        frameBorder="0"
      />
    </div>
  )
}

export default VideoBackground