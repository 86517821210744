import React from 'react'
import { motion } from 'framer-motion'

import { images } from '../../constants'
import './HeaderAlt.scss'

import { AppWrap, MotionWrap } from '../../wrapper'

const scaleVariants = {
    whileInView: {
        scale: [0, 1],
        opacity: [0, 1],
        transition: {
            duration: 1,
            ease: 'easeInOut'
        }
    }
}

const HeaderAlt = () => {
    return (
        <div className='app__header app__flex'>
            <motion.div
                whileInView={{ x: [-100, 0], opacity: [0, 1] }}
                transition={{ duration: 0.5 }}
                className='app__header-info'>
                <div className="app__header-badge">
                    <div className='app__header-info-content'>
                        <div className="badge-cmp app__flex">
                            <h1 className='head-text'><span>Marc Combalia</span>,</h1>
                            <h1 className='head-text'><span>AI</span> Consultant</h1>
                        </div>
                        <div className="tag-cmp app__flex">
                            <p className='p-text'>Artificial Intelligence Lead</p>
                        </div>
                    </div>
                </div>
            </motion.div>
            {/* 
            <motion.div
                whileInView={{ opacity: [0, 1] }}
                transition={{ duration: 0.5, delayChildren: 0.5 }}
                className='app__header-img'>
                <img src={images.ai_profile} alt="profile_bg" />
                <motion.img
                    whileInView={{ scale: [0, 1] }}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                    src={images.circle}
                    alt='profile_circle'
                    className='overlay_circle' />
            </motion.div>
            */}
            <motion.div
                whileInView={scaleVariants.whileInView}
                variant={scaleVariants}
                className='app__header-circles'>
                    <div className="app__header-circles-container">
                        {[images.postgres, images.kubernetes, images.docker].map((circle, index) => (
                            <motion.div whileHover={{scale:[1, 1.2]}} transition={{duration: 0.3}} className="circle-cmp app__flex" key={`circle-${index}`}>
                                <img src={circle} alt="circle" />
                            </motion.div>
                        ))}
                    </div>
                    <div className="app__header-circles-container">
                        {[images.opencv, images.keras, images.pytorch].map((circle, index) => (
                            <motion.div whileHover={{scale:[1, 1.2]}} transition={{duration: 0.3}} className="circle-cmp app__flex" key={`circle-${index}`}>
                                <img src={circle} alt="circle" />
                            </motion.div>
                        ))}
                    </div>
                    <div className="app__header-circles-container">
                        {[images.react, images.sass, images.django].map((circle, index) => (
                            <motion.div whileHover={{scale:[1, 1.2]}} transition={{duration: 0.3}} className="circle-cmp app__flex" key={`circle-${index}`}>
                                <img src={circle} alt="circle" />
                            </motion.div>
                        ))}
                    </div>
            </motion.div>
        </div>
    )
}

export default AppWrap(HeaderAlt, 'home', '', true)
