import React from 'react'
import { BsTwitter, BsInstagram, BsLinkedin, BsGoogle } from 'react-icons/bs'
import { FaFacebookF } from 'react-icons/fa'

const SocialMedia = () => {
    return (
        <div className='app__social'>
            <div>
                <a href="https://scholar.google.com/citations?user=LIvAi2wAAAAJ&hl=es" target='_blank'>
                    <BsGoogle />
                </a>
            </div>
            <div>
                <a href="https://www.linkedin.com/in/marc-combalia-40b26b71/" target='_blank'>
                    <BsLinkedin />
                </a>
            </div>
        </div>
    )
}

export default SocialMedia