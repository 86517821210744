import React from 'react';

import { About, Footer, HeaderAlt3, Research, Projects, Skills, Testimonial, Work, Experience} from './container'
import { Navbar } from './components';

import './App.scss'


const App = () => {
  return (
    <div className="app">
      <Navbar />
      <HeaderAlt3 />
      <Skills />
      <Experience />
      <Projects />
      <Research />
      <Footer />

      {/*
      <Footer />
      <About />
      <Work />
      <Testimonial />
      */}
    </div>
  )
};

export default App;