import React, { useState, useEffect } from 'react'

import { motion } from 'framer-motion'
import ReactTooltip from 'react-tooltip'
import { AppWrap, MotionWrap } from '../../wrapper'
import { urlFor, client } from '../../client'

import './Experience.scss'

const Skills = () => {
  const [skills, setSkills] = useState([])
  const [experiences, setExperiences] = useState([])

  useEffect(() => {
    const query = '*[_type == "workExperience"]';
    const skillsQuery = '*[_type == "skills"]';
    client.fetch(query)
      .then((data) => {
      // ((data.startyear > data.startyear) ? 1 : (b.startyear > a.startyear) ? -1 : 0)

        setExperiences(data.sort((a,b) => (data.endyear > data.endyear) ? 1 : (b.endyear > a.endyear) ? -1 : ((data.startyear > data.startyear) ? 1 : (b.startyear > a.startyear) ? -1 : 0)).reverse())
      })
  }, [])

  return (
    <>
      <h1 className="head-text"><span>Work</span> Experience</h1>
      <div className='app__experience-container'>
        <motion.div
          className='app__experience-exp-works'>
          {experiences.map((work, index) => (
            <div key={'work' + index}>
              <motion.div
                whileInView={{ opacity: [0, 1] }}
                transition={{ duration: 0.5 }}
                className='app__experience-exp-work app__flex'
                key={'work' + index}
                data-tip
                data-for={work.name}
              >
                <div className='app__experience-exp-work-container'>
                  <h4 className='bold-text work-year'>{work.startyear} - {work.endyear}</h4>
                  <div className='work-content'>
                    <h4 className='bold-text'>{work.name}</h4>
                    <p className='p-text work-company'>{work.company}</p>
                    <p className='p-text work-desc'>{work.desc}</p>
                    <ReactTooltip
                      id={work.name}
                      effect='solid'
                      arrowColor='#fff'
                      className='skills-tooltip'>
                      {work.desc}
                    </ReactTooltip>
                  </div>
                </div>
              </motion.div>
            </div>
          ))}
        </motion.div>
      </div>
    </>
  )
}



export default AppWrap(
  Skills,
  'experience',
  "app__primarybg");
