import React from 'react'
import { motion } from 'framer-motion'

import { images } from '../../constants'
import './HeaderAlt2.scss'

import {VideoBackground} from '../../components';
import { AppWrap, MotionWrap } from '../../wrapper'

const scaleVariants = {
    whileInView: {
        scale: [0, 1],
        opacity: [0, 1],
        transition: {
            duration: 1,
            ease: 'easeInOut'
        }
    }
}

const HeaderAlt = () => {
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 1,
        },
      };

    const onReady = (event) => {
        event.target.pauseVideo();
    }


    return (
        <div className='app__header app__flex'>
            <motion.div
                whileInView={{ x: [-100, 0], opacity: [0, 1] }}
                transition={{ duration: 0.5 }}
                className='app__header-info'>
                <div className="app__header-badge">
                    <div className='app__header-info-content'>
                        <div className="badge-cmp app__flex">
                            <h1 className='head-text'><span>Marc Combalia</span>,</h1>
                            <h1 className='head-text'><span>AI</span> Consultant</h1>
                        </div>
                        <div className="tag-cmp app__flex">
                            <p className='p-text'>Artificial Intelligence Lead</p>
                        </div>
                    </div>
                </div>
            </motion.div>
            <motion.div
                whileInView={scaleVariants.whileInView}
                variant={scaleVariants}
                transition={{ duration: 0.5, delayChildren: 0.5 }}
                className='app__header-img'>
                <VideoBackground/>
                {/*
                <img src={images.prof_pic} alt="profile_bg" />
                <motion.img
                    whileInView={{ scale: [0, 1] }}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                    src={images.circle}
                    alt='profile_circle'
                    className='overlay_circle' />
                */}
            </motion.div>
            <motion.div
                whileInView={scaleVariants.whileInView}
                variant={scaleVariants}
                className='app__header-circles'>
                {[images.pytorch, images.docker, images.postgres].map((circle, index) => (
                    <div className="circle-cmp app__flex" key={`circle-${index}`}>
                        <img src={circle} alt="circle" />
                    </div>
                ))}
            </motion.div>
        </div>
    )
}

export default AppWrap(HeaderAlt, 'home', '', true)
